import React from 'react';
import { PathService } from '../services';
import { SearchViewContext, TemplateProps } from '../types';
import { SearchView } from '../views';

const Search: React.FunctionComponent<TemplateProps<SearchViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return <SearchView items={JSON.parse(pageContext.items)} />;
};

export default Search;
